/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open%20Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap");
/* Font awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

/* latin-ext */
@font-face {
  font-family: "OptimusPrinceps";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/font/optimusprinceps/OptimusPrincepsSemiBold.ttf) format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.outline_black {
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000;
}

.font-default {
  font-family: "Open Sans", sans-serif;
}

.font-title {
  font-family: "OptimusPrinceps", "Open Sans", sans-serif;

  /* text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000; */

  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }

  .home_bg_players {
    background-image: url("/images/home/playersSay.png");
  }
  .home_bg_adventure {
    background-image: url("/images/home/adventure.png");
  }
  .home_bg_fantasy_world {
    background-image: url("/images/home/fantasy_world.png");
  }
  .home_bg_future {
    background-image: url("/images/home/future.png");
  }
}

.text-shadow-heavy {
  text-shadow: 7px 7px 10px rgba(0, 0, 0, 1), -7px 7px 10px rgba(0, 0, 0, 1), 7px -7px 10px rgba(0, 0, 0, 1),
    -7px -7px 10px rgba(0, 0, 0, 1);
}

.bg-separator {
  background: url("/images/separator.png") center center/cover;
}

@layer utilities {
  .clip-left-image {
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
  }
  .clip-middle-image {
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  }
  .clip-right-image {
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.bg_grey {
  background: url("/images/bg_default_grey.jpg") center center/cover;
}

.bg_brown {
  background: url("/images/bg_default_brown.jpg") center center/cover;
}

.bg_brown_overflowing {
  background: url("/images/bg_default_brown.jpg") center;
}

.bg_default {
  background: url("/images/bg_default.jpg") center center/cover;
  background-repeat: repeat-y;
}

.bg_menu {
  background: url("/images/bg_menu.jpg") center center/cover;
}

.text-shadow-white:hover {
  text-shadow: 0 0 5px rgb(212, 212, 212, 0.864);
}

.revert-all * {
  all: revert;
}

/* Roadmap */

.roadmap li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 0.75em;
}

.roadmap li::before {
  content: "";
  background-size: cover;
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(50%);
}

.roadmap li.done {
  color: grey;
  text-decoration-line: line-through;
}
.roadmap li.done::before {
  filter: brightness(110%);
}

.roadmap ul.fire li::before {
  background-image: url("/images/element_fire.png");
}
.roadmap ul.water li::before {
  background-image: url("/images/element_water.png");
}
.roadmap ul.air li::before {
  background-image: url("/images/element_air.png");
}
.roadmap ul.earth li::before {
  background-image: url("/images/element_earth.png");
}

/* vision  */

.vision h2 {
  margin: 3rem 0 2rem 0;
}
.vision h3 {
  margin: 2.5rem 0 1.25rem 0;
}

/* Referrals */

.text_outline {text-shadow: 1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black}
.text_outline_big {text-shadow: 2px 2px 0px black, -2px -2px 0px black, 2px -2px 0px black, -2px 2px 0px black}

/* Login */

.custom-wallet-button {@apply w-full}
.custom-wallet-button button.wallet-adapter-button {
  @apply rounded-lg bg-gray-200 w-full shadow-md h-12  text-black font-normal	text-gray-900 text-base text-center		;
  font-family: "Open Sans", sans-serif;
}
.custom-wallet-button button.wallet-adapter-button:hover {
  @apply hover:bg-gray-100;
}
.custom-wallet-button .wallet-adapter-dropdown { width: 100%; }

/* shop */

[data-testid="wallet-list"] div:nth-child(2) {
  display: none; /* Hack to block connection with another wallet */
}